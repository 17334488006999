<template>
  <div>
    <b-card>
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form
          v-else
          novalidate
          @submit.prevent='onSubmit'
        >
          <b-row class='align-items-end'>

            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('title')"
                vid='title'
                rules='required'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('title') }}</span>
                  </template>
                  <b-form-input
                    v-model='form.title'
                    :placeholder="$t('title')"
                    autocomplete='off'
                    type='text'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('body')"
                vid='body'
               >
                <b-form-group
                  :label="$t('body')"
                >
                  <b-form-textarea
                    v-model='form.body'
                    :placeholder="$t('body')"
                    autocomplete='off'
                    rows='5'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- submit and reset -->
            <b-col cols='12 text-right'>
              <LoadingButton />
              <b-button
                type='reset'
                variant='outline-secondary'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import _ from 'lodash'

export default {
  mixins: [formMixin],
  data() {
    return {
      form: {
        title: null,
        body: null,
      },
      isDataLoaded: false,

    }
  },

  computed: {
    toRedirectResource() {
      return 'notification-messages'
    },
  },
  created() {
    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            title: data.title,
            body: data.body,
          }
          this.isDataLoaded = true
        })
    },

    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (this.isEdit) {

        this.form_data = {
          ...$form,
          _method: 'put',
        }
      }
      return $form
    },

  },
}
</script>
